export const TherapistJobApplicationAgentRequiredAction = Object.freeze({
    NONE: "none",
    HANDLE_UNSCHEDULED_FVA: "handle_unscheduled_fva"
});
export const TherapistJobApplicationAgentRequiredActionLabel = Object.freeze({
    [TherapistJobApplicationAgentRequiredAction.NONE]: "Brak",
    [TherapistJobApplicationAgentRequiredAction.HANDLE_UNSCHEDULED_FVA]: "Manualne umówienie wideorozmowy",
});
export const TherapistJobApplicationAgentRequiredActionSelectItems = Object.values(TherapistJobApplicationAgentRequiredAction).map(it => {
    return {
        text: TherapistJobApplicationAgentRequiredActionLabel[it],
        value: it
    };
});

export const TherapistJobApplicationStatus = Object.freeze({
    IN_PROGRESS: "in_progress",
    COMPLETED: "completed",
    REJECTED: "rejected"
});
export const TherapistJobApplicationStatusLabel = Object.freeze({
    [TherapistJobApplicationStatus.IN_PROGRESS]: "W trakcie",
    [TherapistJobApplicationStatus.COMPLETED]: "Zakończone",
    [TherapistJobApplicationStatus.REJECTED]: "Odrzucone",
});
export const TherapistJobApplicationStatusColorLabel = Object.freeze({
    [TherapistJobApplicationStatus.IN_PROGRESS]: "primary",
    [TherapistJobApplicationStatus.COMPLETED]: "secondary",
    [TherapistJobApplicationStatus.REJECTED]: "error"
});
export const TherapistJobApplicationStatusSelectItems = Object.values(TherapistJobApplicationStatus).map(it => {
    return {
        text: TherapistJobApplicationStatusLabel[it],
        value: it
    };
});

export const TherapistJobApplicationStage = Object.freeze({
    CV_VERIFICATION: "cv_verification",
    FVA_QUALIFIED: "fva_qualified",
    FVA_SCHEDULED: "fva_scheduled",
    FVA_COMPLETED: "fva_completed",
    PD_FORM: "pd_form",
    PD_FILES: "pd_files",
    PD_VERIFICATION: "pd_verification",
    CONTRACT_PREPARATION: "contract_preparation",
    CONTRACT_DRAFT: "contract_draft",
    CONTRACT_SIGNED: "contract_signed",
    TH_PROFILE: "th_profile",
    TH_PROFILE_VERIFICATION: "th_profile_verification",
    GOOGLE_WORKSPACE_INFO: "google_workspace_info",
    ONBOARDING_VIDEO_COURSE: "onboarding_video_course"
});
export const TherapistJobApplicationStageLabel = Object.freeze({
    [TherapistJobApplicationStage.CV_VERIFICATION]: "Weryfikacja CV",
    [TherapistJobApplicationStage.FVA_QUALIFIED]: "Wybór terminu rozmowy",
    [TherapistJobApplicationStage.FVA_SCHEDULED]: "Rozmowa zaplanowana",
    [TherapistJobApplicationStage.FVA_COMPLETED]: "Rozmowa zakończona ",
    [TherapistJobApplicationStage.PD_FORM]: "Formularz danych osobowych",
    [TherapistJobApplicationStage.PD_FILES]: "Pliki i dokumenty",
    [TherapistJobApplicationStage.PD_VERIFICATION]: "Weryfikacja danych i plików",
    [TherapistJobApplicationStage.CONTRACT_PREPARATION]: "Przygotowywanie umowy",
    [TherapistJobApplicationStage.CONTRACT_DRAFT]: "Draft umowy",
    [TherapistJobApplicationStage.CONTRACT_SIGNED]: "Umowa podpisana",
    [TherapistJobApplicationStage.TH_PROFILE]: "Profil terapeuty",
    [TherapistJobApplicationStage.TH_PROFILE_VERIFICATION]: "Weryfikacja profilu",
    [TherapistJobApplicationStage.GOOGLE_WORKSPACE_INFO]: "Inf. o Google Workspace",
    [TherapistJobApplicationStage.ONBOARDING_VIDEO_COURSE]: "Kurs wideo",
});
export const TherapistJobApplicationStageSelectItems = Object.values(TherapistJobApplicationStage).map(it => {
    return {
        text: TherapistJobApplicationStageLabel[it],
        value: it
    };
});

export const TherapistJobApplicationChangelogEvent = Object.freeze({
    STATUS_CHANGED: "status_changed",
    STAGE_CHANGED: "stage_changed",
    AGENT_ASSIGNED: "agent_assigned",
    OTHER: "other"
});
export const TherapistJobApplicationChangelogEventLabel = Object.freeze({
    [TherapistJobApplicationChangelogEvent.STATUS_CHANGED]: "Zmiana statusu",
    [TherapistJobApplicationChangelogEvent.STAGE_CHANGED]: "Zmiana etapu",
    [TherapistJobApplicationChangelogEvent.AGENT_ASSIGNED]: "Przypisanie agenta",
    [TherapistJobApplicationChangelogEvent.OTHER]: "Inne",
});


export const TherapistJobApplicationChangelogAuthorType = Object.freeze({
    CANDIDATE: "candidate",
    SYSTEM: "system",
    USER: "user"
});

export const TherapistJobApplicationBusinessType = Object.freeze({
    SOLE_PROPRIETORSHIP: "sole_proprietorship",
    UNREGISTERED_BUSINESS_ACTIVITY: "unregistered_business_activity"
});
export const TherapistJobApplicationBusinessTypeLabel = Object.freeze({
    [TherapistJobApplicationBusinessType.SOLE_PROPRIETORSHIP]: "Jednoosobowa dział. gosp.",
    [TherapistJobApplicationBusinessType.UNREGISTERED_BUSINESS_ACTIVITY]: "Nierejestrowana działalność gosp.",
});


export const TherapistJobApplicationSource = Object.freeze({
    RECRUITMENT_PORTAL: "recruitment_portal",
    SOCIAL_MEDIUM: "social_medium",
    CLINIC_WEBSITE: "clinic_website",
    RISIFY_THERAPIST_RECOMMENDATION: "risify_therapist_recommendation",
    NETWORKING_OR_OTHER_RECOMMENDATION: "networking_or_other_recommendation",
    INDUSTRY_EVENT: "industry_event",
    RECRUITMENT_AGENCY: "recruitment_agency",
    PRESS_AD: "press_ad",
    SCHOOL_OR_UNIVERSITY: "school_or_university",
    OTHER: "other"
});


export const TherapistJobApplicationSourceRecruitmentPortal = Object.freeze({
    PSYCHOPRACA_NET: "psychopraca_net",
    PRACUJ_PL: "pracuj_pl"
});

export const TherapistJobApplicationSourceRecruitmentPortalLabel = Object.freeze({
    [TherapistJobApplicationSourceRecruitmentPortal.PSYCHOPRACA_NET]: "psychopraca.net",
    [TherapistJobApplicationSourceRecruitmentPortal.PRACUJ_PL]: "pracuj.pl",
});

export const TherapistJobApplicationSourceSocialMedium = Object.freeze({
    FACEBOOK: "facebook",
    INSTAGRAM: "instagram",
    LINKEDIN: "linkedin",
    TIKTOK: "tiktok"
});

export const TherapistJobApplicationAttachedFileDisplayType = Object.freeze({
    IMAGE: "image",
    PDF: "pdf",
    UNKNOWN: "unknown"
});

export const TherapistJobApplicationFvaStatus = Object.freeze({
    SCHEDULED: "scheduled",
    CANCELED: "canceled"
});

export const TherapistJobApplicationFvaStatusLabel = Object.freeze({
    [TherapistJobApplicationFvaStatus.SCHEDULED]: "Zaplanowane",
    [TherapistJobApplicationFvaStatus.CANCELED]: "Anulowane",
});

export const TherapistJobApplicationContractEntityType = Object.freeze({
    NATURAL_PERSON: "natural_person",
    SOLE_PROPRIETORSHIP: "sole_proprietorship",
    COMPANY: "company"
});

export const TherapistJobApplicationContractEntityTypeLabel = Object.freeze({
    [TherapistJobApplicationContractEntityType.NATURAL_PERSON]: "Osoba fizyczna",
    [TherapistJobApplicationContractEntityType.SOLE_PROPRIETORSHIP]: "Jednoosobowa dział. gosp.",
    [TherapistJobApplicationContractEntityType.COMPANY]: "Firma",
});

export const TherapistJobApplicationContractEntityTypeSelectItems = Object.values(TherapistJobApplicationContractEntityType).map(it => {
    return {
        text: TherapistJobApplicationContractEntityTypeLabel[it],
        value: it
    };
});

export const TherapistJobApplicationSimpleVerificationStatus = Object.freeze({
    UNVERIFIED: "unverified",
    ACCEPTED: "accepted",
    REJECTED: "rejected"
});

export const TherapistJobApplicationSimpleVerificationStatusLabel = Object.freeze({
    [TherapistJobApplicationSimpleVerificationStatus.UNVERIFIED]: "Nie zweryfikowano",
    [TherapistJobApplicationSimpleVerificationStatus.ACCEPTED]: "Zaakceptowano",
    [TherapistJobApplicationSimpleVerificationStatus.REJECTED]: "Odrzucono",
});

export const TherapistJobApplicationVerificationSelectItems = [
    {
        text: 'Weryfikacja pozytywna',
        value: 'positive'
    },
    {
        text: 'Weryfikacja negatywna',
        value: 'negative'
    }
]