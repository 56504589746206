import { TherapistJobApplicationStage, TherapistJobApplicationStatus } from "@/enums/TherapistJobApplication";

// STORE PRZEZNACZONY DO ZARZĄDZANIA TABLICĄ KANBAN

function createStage() {
    return {
        has_more: true,
        pagination: {
            limit: 24,
            after: null,
            total: null
        },
        is_loading: false
    };
}
function createStagesState() {
    return Object.values(TherapistJobApplicationStage).reduce((stages, stageName) => {
        stages[stageName] = createStage();
        return stages;
    }, {});
}

export default {
    namespaced: true,

    state: () => ({
        applications: [],
        stages: createStagesState(),
        filter_agent: null,
    }),

    getters: {
        getDisplayedApplicationsByStage: (state) => stage => {
            return state.applications.filter(app => app.stage === stage);
        },
        getApplicationIndexById: state => appId => {
            return state.applications.findIndex(app => app._id === appId);
        },
        isStageLoading: state => stage => state.stages[stage]?.is_loading || false,
        hasMoreForStage: state => stage => state.stages[stage]?.has_more ?? true
    },

    mutations: {
        insertApplications(state, apps){
            if(!Array.isArray(apps)) apps = [apps];

            const NEW_ELS = [];
            for(let i = 0; i< apps.length; i++) {
                NEW_ELS.push(apps[i]);
            }

            if(NEW_ELS.length > 0) {
                state.applications = state.applications.concat(NEW_ELS);
            }
        },

        setFilterAgent(state, filter_agent) {
            state.filter_agent = filter_agent;
        },
        setPagination(state, { stage, pagination }) {
            state.stages[stage].pagination = pagination;
            state.stages[stage].has_more = !!pagination.after;
        },
        setLoading(state, { stage, is_loading }) {
            state.stages[stage].is_loading = is_loading;
        },
        setApplications(state, applications) {
            state.applications = applications;
        },
        clearAllApplications(state) {
            const currentAgent = state.filter_agent;
            state.stages = createStagesState();
            state.applications = [];
            state.filter_agent = currentAgent;
        },
    },

    actions: {
        async fetchApplicationsByStage(
            { commit, state },
            { stage, status = "in_progress", loadMore = false }
        ) {
            commit("setLoading", { stage, is_loading: true });

            try {
                const response = await this._vm.$axios.$get("/therapist-job-applications/", {
                    params: {
                        filter_status: status,
                        filter_stage: stage,
                        sort_direction: "asc",
                        sort_field: "last_stage_change_date",
                        limit: state.stages[stage]?.pagination.limit || 24,
                        after: loadMore ? state.stages[stage]?.pagination.after : null,
                        filter_agent: state.filter_agent
                    }
                });

                if (loadMore) {
                    commit("insertApplications", response.therapist_job_applications);
                } else {
                    commit("setApplications", response.therapist_job_applications);
                }

                commit("setPagination", { stage, pagination: response.pagination });
            } catch (error) {
                console.error(error);
            }

            commit("setLoading", { stage, is_loading: false });
        },
        async handleApplicationUpdate({ commit, state, getters }, updated_app) {
            const index = getters.getApplicationIndexById(updated_app._id);
            if (index === -1) return;

            // Jeżeli status aplikacji nie jest `IN_PROGRESS`, usuwamy ją
            if (updated_app.status !== TherapistJobApplicationStatus.IN_PROGRESS) {
                state.applications = state.applications.filter(app => app._id !== updated_app._id);
            } else {
                // W przeciwnym razie aktualizujemy aplikację
                state.applications.splice(index, 1, updated_app);
            }
        },
        async handleNewApplication({ commit, getters }, new_app) {
            const existing_app_index = getters.getApplicationIndexById(new_app._id);
            if (existing_app_index === -1) {
                commit("insertApplications", new_app);
            }
        }
    }
};
