import { io } from "socket.io-client";
import store from "../store";
import { IoEvent } from "@/enums/SocketIo";
import { IoEmit } from "../enums/SocketIo";
// import { playNewNotificationSound } from "../helpers/sounds";

const IO_INSTANCE = io(store.state.API_BASE_URL, {
    transports: ["websocket"],
    autoConnect: false
});
const JOINED_ROOMS = new Set();
export function joinRoom(io_client, room_name) {
    return new Promise((resolve, reject) => {
        try {
            io_client.emit(
                IoEmit.JOIN_ROOM,
                {
                    room: room_name
                },
                e => {
                    if (e.success === true) {
                        JOINED_ROOMS.add(room_name);
                    }
                    console.log(e.msg);
                    return resolve(e);
                }
            );
        } catch (err) {
            return reject(err);
        }
    });
}

export function leaveRoom(io_client, room_name) {
    return new Promise((resolve, reject) => {
        try {
            io_client.emit(
                IoEmit.LEAVE_ROOM,
                {
                    room: room_name
                },
                e => {
                    JOINED_ROOMS.delete(room_name);
                    console.log(e.msg);
                    return resolve(e);
                }
            );
        } catch (err) {
            return reject(err);
        }
    });
}

export function install(Vue) {
    Vue.prototype.$io = IO_INSTANCE;

    Vue.prototype.$io.io.on("reconnect", async () => {
        console.log(`[Socket.io] Reconnected`);
        if (store.getters["auth/isLoggedIn"]) {
            await store.dispatch("auth/socketJoinRooms");
        }

        const PRTJ = [...JOINED_ROOMS];
        JOINED_ROOMS.clear();
        for (let i = 0; i < PRTJ.length; i++) {
            await joinRoom(IO_INSTANCE, PRTJ[i]);
        }
    });
    Vue.prototype.$io.on("disconnect", async reason => {
        store.state.auth.socket_room_joined = false;
        console.log(`[Socket.io] Disconnected (${reason})`);
    });
    Vue.prototype.$io.on(IoEvent.THERAPIST_JOB_APPLICATION_ITEM_UPDATED, async payload => {
        const has_permissions = store.getters["auth/userHasPermissions"](
            "therapist_job_applications.list"
        );

        if (!has_permissions) return;

        try {
            const res = await Vue.prototype.$axios.$get(
                `/therapist-job-applications/${payload.therapist_job_application}`
            );
            const updated_app = res.therapist_job_application;

            store.dispatch("tja/handleApplicationUpdate", updated_app);
        } catch (error) {
            console.error(error);
        }
    });
    Vue.prototype.$io.on(IoEvent.THERAPIST_JOB_APPLICATION_ITEM_CREATED, async payload => {
        const has_permissions = store.getters["auth/userHasPermissions"](
            "therapist_job_applications.list"
        );

        if (!has_permissions) return;

        try {
            const res = await Vue.prototype.$axios.$get(
                `/therapist-job-applications/${payload.therapist_job_application}`
            );
            const new_app = res.therapist_job_application;

            store.dispatch("tja/handleNewApplication", new_app);
        } catch (error) {
            console.error(error);
        }
    });
}
