import { EventBus } from "../components/EventBus";

let window_is_focused = true;
window.addEventListener("focus", () => {
    window_is_focused = true;
    emitBrowserFocusEvent();
});
window.addEventListener("blur", () => {
    window_is_focused = false;
    emitBrowserFocusEvent();
});

let tab_is_visible = true;
document.addEventListener("visibilitychange", () => {
    tab_is_visible = document.visibilityState === "visible";
    emitBrowserFocusEvent();
});

let ebfe_debouncer = null;
function emitBrowserFocusEvent() {
    if (ebfe_debouncer !== null) clearTimeout(ebfe_debouncer);
    ebfe_debouncer = setTimeout(() => {
        EventBus.emit("Browser::activestatechange", window_is_focused && tab_is_visible);
        ebfe_debouncer = null;
    }, 10);
}

export async function appIsFocused() {
    return document.hasFocus();
}
