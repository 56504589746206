import { EventBus } from "../../components/EventBus";
import waitForMs from "../../helpers/waiters";
import { HelpdeskThreadStatus, getHelpdeskCategoryByStatus } from "../../enums/HelpdeskThread";
import { HelpdeskMessageAuthorType, HelpdeskMessageStatus } from "../../enums/HelpdeskMessage";

export default {
    namespaced: true,

    state: () => ({
        helpdesk_threads: [],
        helpdesk_threads_pagination: {
            [HelpdeskThreadStatus.ACTIVE]: {
                loading: false,
                current_page: 0,
                number_of_pages: 1,
                items_per_page: 16,
                number_of_items: false
            },
            [HelpdeskThreadStatus.CLOSED]: {
                loading: false,
                current_page: 0,
                number_of_pages: 1,
                items_per_page: 16,
                number_of_items: false
            }
        },

        last_active_tab: HelpdeskThreadStatus.ACTIVE,
        sp_cache: {
            [HelpdeskThreadStatus.ACTIVE]: 0,
            [HelpdeskThreadStatus.CLOSED]: 0
        },
        filter_cache: {
            [HelpdeskThreadStatus.ACTIVE]: true,
            [HelpdeskThreadStatus.CLOSED]: true
        },

        helpdesk_threads_mounted: false,
        active_helpdesk_thread: null
    }),

    getters: {
        findHelpdeskThread: state => id => {
            return state.helpdesk_threads.find(it => it._id == id);
        },
        getHelpdesk: state => {
            return state.helpdesk_threads.sort((a, b) => b.la_date - a.la_date);
        },
        getHelpdeskThreadsFilteredByStatus: state => status => {
            return state.helpdesk_threads
                .filter(it => it.status == status)
                .sort((a, b) => b.la_date - a.la_date);
        },

        hasUnreadMessages: (state, getters, rootState) => helpdesk_thread_id => {
            const HT = state.helpdesk_threads.find(it => it._id === helpdesk_thread_id);
            if (!HT) return false;

            if (
                HT.last_helpdesk_message &&
                HT.last_helpdesk_message.author._id === HT.user._id &&
                HT.last_helpdesk_message.is_read === false &&
                (!HT.assistant || HT.assistant._id === rootState.auth.user._id)
            ) {
                return true;
            }

            return false;
        },

        // zwraca string z datą i/lub godziną w zależności od tego jak w stosunku do teraz plasuje się zadana data
        parseDate:
            () =>
            (d, opts = {}) => {
                if (typeof d != "number") {
                    d = new Date(d).getTime();
                }

                const now = Date.now();

                if (now - d <= 0) {
                    return "Invalid input date";
                }

                const now_date = new Date(now);
                const d_date = new Date(d);

                const weekdays = [
                    {
                        full: "Niedziela",
                        short: "nd."
                    },
                    {
                        full: "Poniedziałek",
                        short: "pn."
                    },
                    {
                        full: "Wtorek",
                        short: "wt."
                    },
                    {
                        full: "Środa",
                        short: "śr."
                    },
                    {
                        full: "Czwartek",
                        short: "czw."
                    },
                    {
                        full: "Piątek",
                        short: "pt."
                    },
                    {
                        full: "Sobota",
                        short: "sob."
                    }
                ];

                // 1. jeżeli między datami jest < 24h różnicy i jednocześnie jest to ta sama data to zwracamy sam stamp godzinowy
                if (now - d < 24 * 60 * 60 * 1000 && now_date.getDate() === d_date.getDate()) {
                    return `${d_date.getHours().toString().pad(2)}:${d_date
                        .getMinutes()
                        .toString()
                        .pad(2)}`;
                }

                // 2. jeżeli jest mniej niż 7 dni różnicy i jest to jednocześnie ten sam tydzień to zwracamy skróconą nazwę dnia tygodnia + godzinę
                if (now - d < 7 * 24 * 60 * 60 * 1000 && now_date.getWeek() == d_date.getWeek()) {
                    return `${weekdays[d_date.getDay()].short} ${d_date
                        .getHours()
                        .toString()
                        .pad(2)}:${d_date.getMinutes().toString().pad(2)}`;
                }

                // 3. jeżeli poprzednie przypadki nie złapały to zwracamy według przekazanych opcji
                if (opts.hide_time === true) {
                    return `${d_date.getDate().toString().pad(2)}.${(d_date.getMonth() + 1)
                        .toString()
                        .pad(2)}.${d_date.getFullYear()}`;
                }
                return `${d_date.getDate().toString().pad(2)}.${(d_date.getMonth() + 1)
                    .toString()
                    .pad(2)}.${d_date.getFullYear()} ${d_date.getHours().toString().pad(2)}:${d_date
                    .getMinutes()
                    .toString()
                    .pad(2)}`;
            }
    },

    mutations: {
        setActiveHelpdeskThread(state, thread) {
            state.active_helpdesk_thread = thread;
        },

        nuke(state) {
            state.helpdesk_threads = [];
            state.helpdesk_threads_pagination = {
                [HelpdeskThreadStatus.ACTIVE]: {
                    loading: false,
                    current_page: 0,
                    number_of_pages: 1,
                    items_per_page: 16,
                    number_of_items: false
                },
                [HelpdeskThreadStatus.CLOSED]: {
                    loading: false,
                    current_page: 0,
                    number_of_pages: 1,
                    items_per_page: 16,
                    number_of_items: false
                }
            };

            state.helpdesk_threads_mounted = false;
            state.helpdesk_messages = [];
        }
    },

    actions: {
        // 1. HELPDESK THREADS
        addHelpdeskThreads({ state, getters }, helpdesk_threads = []) {
            if (!Array.isArray(helpdesk_threads)) helpdesk_threads = [helpdesk_threads];

            const A = [];
            for (let i = 0; i < helpdesk_threads.length; i++) {
                if (
                    state.helpdesk_threads.findIndex(it => it._id === helpdesk_threads[i]._id) ===
                    -1
                ) {
                    A.push(helpdesk_threads[i]);
                }
            }

            if (A.length > 0) {
                state.helpdesk_threads = state.helpdesk_threads.concat(A);
            }
        },
        updateHelpdeskThread({ state }, helpdesk_thread) {
            const ix = state.helpdesk_threads.findIndex(it => it._id == helpdesk_thread._id);
            if (ix === -1) return;

            const OLD_CATEGORY = state.helpdesk_threads[ix].status;
            const NEW_CATEGORY =
                helpdesk_thread.status !== undefined ? helpdesk_thread.status : false;

            state.helpdesk_threads.splice(ix, 1, {
                ...state.helpdesk_threads[ix],
                ...helpdesk_thread
            });

            // przy zmianie kategorii zmiana w ilości elementów dla paginacji
            if (helpdesk_thread.status != undefined && OLD_CATEGORY != NEW_CATEGORY) {
                state.helpdesk_threads_pagination = {
                    ...state.helpdesk_threads_pagination,
                    [OLD_CATEGORY]: {
                        ...state.helpdesk_threads_pagination[OLD_CATEGORY],
                        number_of_items:
                            state.helpdesk_threads_pagination[OLD_CATEGORY].number_of_items - 1
                    },
                    [NEW_CATEGORY]: {
                        ...state.helpdesk_threads_pagination[NEW_CATEGORY],
                        number_of_items:
                            state.helpdesk_threads_pagination[NEW_CATEGORY].number_of_items + 1
                    }
                };

                if (
                    NEW_CATEGORY !== false &&
                    state.last_active_tab != NEW_CATEGORY &&
                    state.active_helpdesk_thread == helpdesk_thread._id
                ) {
                    state.last_active_tab = NEW_CATEGORY;
                    EventBus.emit("HelpdeskThreadStatus.toggle", {
                        category: NEW_CATEGORY,
                        force_open: true
                    });
                }
            }
        },

        fetchHelpdeskThreads({ state, rootState }, status) {
            return new Promise(async (resolve, reject) => {
                if (!Object.values(HelpdeskThreadStatus).includes(status)) {
                    return reject("Invalid Helpdesk status");
                }
                if (state.helpdesk_threads_pagination[status].loading) {
                    function check() {
                        if (state.helpdesk_threads_pagination[status].loading) {
                            setTimeout(check, 500);
                        } else {
                            return resolve();
                        }
                    }
                    return check();
                }

                if (
                    state.helpdesk_threads_pagination[status].current_page >=
                    state.helpdesk_threads_pagination[status].number_of_pages
                ) {
                    return resolve();
                }

                state.helpdesk_threads_pagination = {
                    ...state.helpdesk_threads_pagination,
                    [status]: {
                        ...state.helpdesk_threads_pagination[status],
                        loading: true
                    }
                };

                try {
                    const RQ = [
                        "page=" + (state.helpdesk_threads_pagination[status].current_page + 1),
                        "items_per_page=" +
                            state.helpdesk_threads_pagination[status].items_per_page,
                        "filter_status=" + status
                    ];

                    const r = await this._vm.$axios.$get(`/helpdesk-threads/?${RQ.join("&")}`);

                    this.dispatch("helpdesk/addHelpdeskThreads", r.helpdesk_threads);
                    await waitForMs(20);

                    state.helpdesk_threads_pagination = {
                        ...state.helpdesk_threads_pagination,
                        [status]: {
                            ...state.helpdesk_threads_pagination[status],
                            current_page: r.pagination.current_page,
                            number_of_pages: r.pagination.number_of_pages,
                            items_per_page: r.pagination.items_per_page,
                            number_of_items: r.pagination.number_of_items
                        }
                    };

                    state.helpdesk_threads_pagination = {
                        ...state.helpdesk_threads_pagination,
                        [status]: {
                            ...state.helpdesk_threads_pagination[status],
                            loading: false
                        }
                    };

                    return resolve();
                } catch (err) {
                    console.error(err);

                    state.helpdesk_threads_pagination = {
                        ...state.helpdesk_threads_pagination,
                        [status]: {
                            ...state.helpdesk_threads_pagination[status],
                            loading: false
                        }
                    };

                    return reject(err);
                }
            });
        }
    }
};
